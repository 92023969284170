<template>

</template>

<style lang="scss"></style>

<script>

export default {
  components: {
  },
  data () {
    return {
      barcodes:[]
    }
  }
}
</script>
